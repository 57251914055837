<template>
  <div >
    <div class="home" v-if="login">
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" router>
      <el-menu-item route="/test" index="RMHAS">RMHAS</el-menu-item>
      <el-menu-item :disabled="!fundus._lesions" route="/retinalesion" index="Retinal lesion">Retinal lesion</el-menu-item>
    </el-menu>
    <Result />
  </div>
  <div v-else class="sign-in">
    <el-form :model="form" status-icon ref="form" label-width="100px" class="demo-ruleForm">
      <div class="booth-logo"><img src="" /></div>
      <el-form-item label="Username" prop="userName">
        <el-input v-model="form.userName"></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="pass">
        <el-input type="password" v-model="form.pass" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('form')">Submit</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import Result from '../components/Result2.vue'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    Result
  },
  data() {
    return {
      activeIndex: this.$route.path,
      login: window.login,
      form: {
        userName: '',
        pass: ''
      }
    }
  },
  computed: mapState({
    fundus: state => state.fundus.fundus
  }),
  beforeCreate: function () {
    // console.log(12345)
    // this.$store.commit('SET_FUNDUS', {
    //   fundus: {}
    // })
  },
  methods: {
    submitForm () {
      const form = this.form
      if (form.userName === 'test' && form.pass === 'abcde12345') {
        window.login = true
        this.login = true
      } else {
        alert('Password error!')
      }
    }
  }
}
</script>

<style lang="less">
.sign-in {
  width: 500px;
  margin: 50px auto;
}
.home {
  padding-bottom: 20px;
  .home-box {
    position: relative;
    padding: 20px 30px;
    min-height: 520px;
    width: 800px;
    margin: 20px auto 0;
    box-sizing: border-box;
  }
  .home-btn {
    position: absolute;
    bottom: 200px;
    left: 50%;
    width: 250px;
    height: 80px;
    font-size: 30px;
    margin-left: -125px;
  }
}
</style>