import Vue from 'vue'
import VueRouter from 'vue-router'
import Test from '../views/Test.vue'
import Retinalesion from '../views/Retinalesion.vue'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/retinalesion',
    name: 'Retinalesion',
    component: Retinalesion
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
